import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import CardDetailImage from "./CardDetailImage";
import CardDetailTopBar from "./CardDetailTopBar";
import CardDetailBottomBar from "./CardDetailBottomBar";
import { useSelector, useDispatch } from "react-redux";
import { closeCard, setHasOpenedCard } from "../../features/ui/uiSlice";
import { useEffect } from "react";
import { PiHandTap } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { useRef } from "react";
import { useState } from "react";
import "./Card.css";

const CardDetailModal = ({ deckProperties }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userState.user);
  const isCardOpen = useSelector((state) => state.uiState.isCardOpen);
  const selectedCard = useSelector((state) => state.cardsState.selectedCard);
  const nextCards = useSelector((state) => state.cardsState.nextCards);
  const hasOpenedCard = useSelector((state) => state.uiState.hasOpenedCard);
  const touchStartY = useRef(null);
  const [animateSwipeUp, setAnimateSwipeUp] = useState(false);

  useEffect(() => {
    if (!user) {
      // Reset the modal state when the user is not authenticated (i.e., logged out)
      dispatch(closeCard());
    }
  }, [user, dispatch]);

  const handleClose = () => {
    dispatch(closeCard());
  };

  const handleCloseInstructions = () => {
    dispatch(setHasOpenedCard());
  };

  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e) => {
  const touchEndY = e.changedTouches[0].clientY;
  const deltaY = touchStartY.current - touchEndY;

  if (deltaY > 100) {
    setAnimateSwipeUp(true);
    setTimeout(() => {
      handleClose();
      setAnimateSwipeUp(false); 
    }, 300); 
  }
};
  return (
    <Dialog
      open={isCardOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullScreen
      PaperProps={{
        style: {
          backgroundColor: deckProperties.backgroundColor,
          backgroundImage:
            "radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(253,253,253,0.2133447128851541) 43%, rgba(34,19,57,0) 75%)",
          boxShadow: "none",
          position: "relative",
        },
      }}
      className="supenseAnimation"
    >
      {/*!hasOpenedCard && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "absolute",
            zIndex: "999",
            display: "flex",
            padding: "60px",
            paddingY: "100px",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <PiHandTap size={70} color="white" />
          <Typography
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: "20px",
              // backgroundColor: "rgba(0, 0, 0, 0.65)",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            Flip the card by performing a double-tap gesture.
          </Typography>
          <RxCross2  onClick={handleCloseInstructions} size={30} color="white"  style={{position: "absolute", top: "30px", right: "30px"}}/>
        </Box>
      )*/}
      <DialogContent
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        sx={{ width: "100%", height: "100%", margin: 0, padding: "20px 10px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            maxWidth: "400px",
            mx: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardDetailTopBar card={selectedCard} />
          <CardDetailImage card={selectedCard} animateSwipeUp={animateSwipeUp} />
          <CardDetailBottomBar
            next={nextCards.nextCard}
            previous={nextCards.previousCard}
            random={nextCards.randomCard}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CardDetailModal;
